import React, { useState, useCallback, Fragment, useContext, useMemo, useRef, MouseEventHandler, useEffect } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { connect } from 'react-redux'
import { getNavItems } from 'selectors/navigationSelectors'
import NavigationNavItem from './NavigationNavItem'
import GeoContext from 'contexts/geoContext'
import { matchPath, RouteComponentProps, withRouter } from 'react-router'
import HorizontalContainerWithFadedOverflow from 'components/Common/HorizontalContainerWithFadedOverflow'
import { mediaQueryUp } from 'components/utils/breakpoint'
import NavigationNavDropdownItem from './NavigationNavDropdownItem'
import * as Analytics from 'analytics/analytics'
import { EmptyArray } from 'lib/array/arrayUtils'

const NavContainer = styled(HorizontalContainerWithFadedOverflow)`
  /*
    We want our menu text to be flush with the edge of the container (it looks nicer)
    Emulate outdent = left and outdent = right on the first/last buttons
  */
  margin-right: -${rem(16)};
  margin-left: -${rem(16)};

  ${mediaQueryUp.desktop} {
    overflow: visible;
    mask-image: none;
    isolation: unset;
  }
`

interface Props extends RouteComponentProps<any> {
  navItems: Array<App.NavItem>;
  pathname: string;
  className?: string;
}

function NavigationNavItems({
  className,
  navItems = EmptyArray,
  pathname,
}: Props) {
  const geo = useContext(GeoContext)
  const containerRef = useRef<HTMLDivElement>(null)

  const [openNav, setOpenNav] = useState<string>()

  const onNavToggle = useCallback((navItem: App.NavItem) => {
    setOpenNav(openNav === navItem.id ? undefined : navItem.id)
  }, [openNav])

  const closeToggle = useCallback<MouseEventHandler>((e) => {
    e.stopPropagation()
    // don't close if the trigger was one of our dropdown triggers
    if (!(e.target as HTMLElement).dataset.toggleid) {
      setOpenNav(undefined)
    }
  }, [])

  const onNavbarSelect = useCallback((selection: string) => {
    Analytics.trackClientEvent({
      subject: selection,
      action: 'clicked',
      category: 'navbar',
      type: 'interaction',
    })
  }, [])

  const onSubNavClick = useCallback((selection: string) => {
    if (openNav) {
      setOpenNav(undefined)
    }
    onNavbarSelect(selection)
  }, [openNav, onNavbarSelect])

  const onNavClick = useCallback((item: App.NavItem) => {
    onNavbarSelect(item?.id ?? item.text)
  }, [onNavbarSelect])

  const activeNavItem = useMemo(() => {
    return navItems.find(nav => {
      if (nav.items?.length) {
        return nav.items.some(childNav => {
          const match = matchPath(pathname, { path: `/${geo.currentRegionCode.toLowerCase()}${childNav.to}` })
          return !!match?.isExact
        })
      }
      const match = matchPath(pathname, { path: `/${geo.currentRegionCode.toLowerCase()}${nav.to}` })
      return !!match?.isExact
    })
  }, [geo.currentRegionCode, navItems, pathname])

  useEffect(() => {
    if (activeNavItem?.id) {
      const activeElement = document.getElementById(activeNavItem.id)

      if (activeElement) {
        activeElement.scrollIntoView({ behavior: 'instant', block: 'center', inline: 'center' })
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <NavContainer
    ref={containerRef}
    role="menu"
    className={className}
  >
    {navItems.map((nav, index) => {
      return <Fragment key={nav.id}>
        {!nav.items?.length && <NavigationNavItem
          active={nav === activeNavItem}
          navItem={nav}
          id={nav.id}
          data-navid={nav.id}
          onClick={onNavClick}
          position={index}
        />}
        {!!nav.items?.length && <NavigationNavDropdownItem
          currentActiveNavItem={activeNavItem}
          isOpen={nav.id === openNav}
          navItem={nav}
          onClose={closeToggle}
          onToggle={onNavToggle}
          onSubNavClick={onSubNavClick}
          position={index}
        />}
      </Fragment>
    })}
  </NavContainer>
}

const mapStateToProps = (state: App.State) => ({
  navItems: getNavItems(state),
  pathname: state.router.location.pathname,
})

export default withRouter(connect(mapStateToProps)(NavigationNavItems))
