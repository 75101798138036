import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import cn from 'clsx'
import HideVisually from 'components/Common/HideVisually'
import config from 'constants/config'
import ClickableLink from 'components/Common/Clickable/ClickableLink'
import LogoIcon from 'components/App/Header/Logo/LogoIcon'
import { ResponsiveLogoProps } from 'components/App/Header/Logo/logos/ResponsiveLuxuryEscapesLogo'
import { checkCanRedeemLuxPlusBenefits, isFreePreviewLuxPlusMember } from 'luxPlus/selectors/featureToggle'
import { useAppSelector } from 'hooks/reduxHooks'
import LuxPlusLabel from 'luxPlus/components/LuxPlusLabel'
import { mediaQueryUp } from 'components/utils/breakpoint'
import { selectLoggedIn } from 'selectors/accountSelectors'

const LogoLink = styled(ClickableLink)`
  flex-shrink: 0;
  height: ${rem(40)};
  display: flex;
  align-items: center;
  gap: ${rem(8)};
`

const LogoContainer = styled.div`
  display: flex;
  flex-shrink: 0;

  &.mobile-icon {
    height: ${rem(32)};
    width: ${rem(40)};
    margin-right: ${rem(-8)}
  }

  &.mobile-text {
    height: ${rem(20)};
  }

  &.tablet-icon {
    ${mediaQueryUp.tablet} {
      height: ${rem(32)};
      width: ${rem(40)};
      margin-right: ${rem(-8)}
    }
  }

  &.tablet-text {
    ${mediaQueryUp.tablet} {
      height: ${rem(20)};
      margin-right: 0;
      width: auto;
    }
  }

  &.desktop-icon {
    ${mediaQueryUp.desktop} {
      height: ${rem(32)};
      width: ${rem(40)};
      margin-right: ${rem(-8)}
    }
  }

  &.desktop-text {
    ${mediaQueryUp.desktop} {
      height: ${rem(24)};
      margin-right: 0;
      width: auto;
    }
  }

  && {
    ${mediaQueryUp.extraLargeDesktop} {
      height: ${rem(24)};
      margin-right: 0;
      width: auto;
    }
  }
`

const LuxPlusPreviewLabelMobile = styled(LuxPlusLabel)`
  align-self: center;

  ${mediaQueryUp.tablet} {
    display: none;
  }
`

const LuxPlusPreviewLabel = styled(LuxPlusLabel)`
  display: none;

  ${mediaQueryUp.tablet} {
    display: flex;
    align-self: center;
  }
`

interface Props extends ResponsiveLogoProps {
  className?: string;
}

function NavBarBrandLogo(props: Props) {
  const {
    className,
  } = props

  const isLuxPlusMember = useAppSelector(state => checkCanRedeemLuxPlusBenefits(state))
  const isFreePreviewMember = useAppSelector(state => isFreePreviewLuxPlusMember(state))
  const isLoggedIn = useAppSelector(state => selectLoggedIn(state))
  const hasLuxBenefits = isLuxPlusMember || isFreePreviewMember

  return (
    <LogoLink to={config.header.logo.to}>
      <LogoContainer
         className={cn(className,
          `mobile-${isLoggedIn ? 'icon' : 'text'}`,
          `tablet-${hasLuxBenefits || isLoggedIn ? 'icon' : 'text'}`,
          `desktop-${hasLuxBenefits ? 'icon' : 'text'}`,
         )}
      >
        <LogoIcon
          mobileVariant={!isLoggedIn ? 'icon+text' : 'icon'}
          tabletVariant={hasLuxBenefits || isLoggedIn ? 'icon' : 'icon+text'}
          desktopVariant={hasLuxBenefits ? 'icon' : 'icon+text'}
          extraLargeDesktopVariant="icon+text"
          height="100%"
          width={null}
        />
      </LogoContainer>
      {hasLuxBenefits && <>
        <LuxPlusPreviewLabel
          data-testid={isFreePreviewMember ? 'lux-plus-label-header-free-preview' : 'lux-plus-label-header-member' }
          type={isFreePreviewMember ? 'plus-preview' : 'plus'}
        />
        <LuxPlusPreviewLabelMobile type="plus" />
      </>}
      <HideVisually>{config.logo.alt}</HideVisually>
    </LogoLink>
  )
}

export default React.memo(NavBarBrandLogo)
