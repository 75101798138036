import ListItem from 'components/Luxkit/List/ListItem'
import { getNavigationMenuIcon } from 'content/components/Icon/utils'
import React from 'react'

interface Props {
  /**
   * The nav item that represnets this menu item
   */
  navItem: App.NavItem;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  /**
   * Doesn't show any icons if they have one available
   */
  hideIcon?: boolean;
}

function NavBarHamburgerMenuItem(props: Props) {
  const { navItem, onClick, hideIcon } = props

  const iconName = navItem.sideNavIconName || navItem.iconName
  const Icon = iconName && getNavigationMenuIcon(iconName)

  return <ListItem
    size="medium"
    title={navItem.text}
    to={navItem.to}
    href={navItem.href}
    startIcon={!hideIcon && Icon && <Icon size="S" />}
    onClick={onClick}
  />
}

export default NavBarHamburgerMenuItem
