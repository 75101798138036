import cn from 'clsx'
import { rem } from 'polished'
import React from 'react'
import styled from 'styled-components'
import Caption from './Typography/Caption'
import { SVG_ICON_SIZE_CSS_VAR } from './SvgIcon'

const Dot = styled.div`
  display: flex;
  align-items: center;
  border-radius: ${props => props.theme.borderRadius.round};
  color: ${props => props.theme.palette.neutral.default.eight};
  box-shadow: 0px 0px 0px 2px ${props => props.theme.palette.neutral.default.eight};

  &.kind-dot {
    height: ${rem(8)};
    width: ${rem(8)};
  }

  &.kind-icon {
    height: ${rem(16)};
    width: ${rem(16)};
    ${SVG_ICON_SIZE_CSS_VAR}: ${rem(16)};
  }

  &.kind-string {
    height: ${rem(16)};
    padding: 0 ${rem(4)};
  }


  &.mode-critical {
    background-color: ${props => props.theme.palette.messaging.critical.normalBackground};
  }

  &.mode-warning {
    background-color: ${props => props.theme.palette.messaging.warning.normalBackground};
  }

  &.mode-highlight {
    background-color: ${props => props.theme.palette.highlight.primary.normalBackground};
  }
`

type DotProps = {
  kind: 'dot'
}

type StringProps = {
  kind: 'string';
  children: React.ReactNode;
}

type IconProps = {
  kind: 'icon';
  icon: React.ReactNode;
}

interface CommonProps {
  className?: string;
  kind: 'dot' | 'string' | 'icon';
  mode: 'critical' | 'warning' | 'highlight';
}

type Props = CommonProps & (DotProps | StringProps | IconProps)

function Badge(props: Props) {
  const { kind, mode, className } = props

  const classes = cn(className, `mode-${mode}`, `kind-${kind}`)
  if (kind === 'dot') {
    return <Dot className={classes} />
  } else if (kind === 'icon') {
    return <Dot className={classes}>{props.icon}</Dot>
  } else {
    return <Dot className={classes}>
      <Caption variant="medium" weight="bold">
        {props.children}
      </Caption>
    </Dot>
  }
}

export default Badge
