import React from 'react'
import Badge from 'components/Luxkit/Badge'
import LineShoppingCartIcon from 'components/Luxkit/Icons/line/LineShoppingCartIcon'
import NavBarButton from '../NavBarButton/NavBarButton'
import useOptimizelyExperiment from 'hooks/Optimizely/useOptimizelyExperiment'
import { OptimizelyExperiments } from 'constants/optimizely'
import { useAppSelector } from 'hooks/reduxHooks'

interface Props {
  className?: string;
}

/**
 * This is for a not-yet implemented cart feature that is being worked
 * on by the CRO team
 */
function NavBarCartButton(props: Props) {
  const enabled = useOptimizelyExperiment(OptimizelyExperiments.croMultiItemCart)
  const cartCount = useAppSelector(state => Object.values(state.auth.userCart.items).length)

  if (!enabled) {
    return null
  }

  return <NavBarButton
    className={props.className}
    to="/user-cart"
    icon={<LineShoppingCartIcon />}
    badge={!!cartCount && <Badge mode="critical" kind="string">
      {cartCount}
    </Badge>}
  />
}

export default React.memo(NavBarCartButton)
