import React from 'react'
import styled from 'styled-components'
import LuxPlusLogo from 'luxPlus/components/Logos/LuxPlusLogo'
import LuxLoyaltyProductLogo from 'luxLoyalty/components/LuxLoyaltyProductLogo'
import Badge from 'components/Luxkit/Badge'
import { rem } from 'polished'
import BodyText from 'components/Luxkit/Typography/BodyText'
import { useAppSelector } from 'hooks/reduxHooks'
import { checkCanRedeemLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import Clickable from 'components/Common/Clickable'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import Group from 'components/utils/Group'

const Logo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 0 ${rem(4)};
  transition: background-color 0.2s;
`

const LogoButton = styled(Clickable)`
  position: relative;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  text-decoration: none;
  flex-shrink: 0;
  touch-action: manipulation;
  cursor: pointer;
  user-select: none;
  background-image: none;
  display: flex;
  align-items: center;
  gap: ${rem(4)};
  height: ${rem(48)};
  padding: ${rem(8)} ${rem(16)};

  &:not(:disabled) {
    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.neutral.default.seven};
      }
    }

    &:focus {
      outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px) solid ${props => props.theme.palette.neutral.default.five};
      outline-offset: 1px;
    }

    &:active {
      background-color: ${props => props.theme.palette.neutral.default.six};
    }
  }
`

interface Props {
  isNew?: boolean;
  variant: 'luxPlus' | 'loyalty';
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

function NavBarHamburgerMenuLoyaltyItem(props: Props) {
  const { variant, isNew, onClick } = props

  const isLuxPlusMember = useAppSelector(state => checkCanRedeemLuxPlusBenefits(state))

  return <LogoButton
    onClick={onClick}
    to={variant === 'luxPlus' ? '/lux-plus' : '/lux-loyalty'}
  >
    <Group direction="horizontal" gap={4} verticalAlign="center">
      <BodyText variant="small" colour="neutral-three" >
        {variant === 'luxPlus' && `${!isLuxPlusMember ? 'Join our ' : ''}VIP Travel Club`}
        {variant === 'loyalty' && 'Earn points with'}
      </BodyText>
      <Logo>
        {variant === 'luxPlus' && <LuxPlusLogo height={12} />}
        {variant === 'loyalty' && <LuxLoyaltyProductLogo height={19}/>}
      </Logo>
    </Group>
    {isNew && <Badge mode="critical" kind="string">New</Badge>}
  </LogoButton>
}

export default NavBarHamburgerMenuLoyaltyItem
