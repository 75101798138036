import React from 'react'
import { SvgIconProps } from 'components/Luxkit/SvgIcon'
import SolidStarIcon from 'components/Luxkit/Icons/solid/SolidStarIcon'
import LineSearchIcon from 'components/Luxkit/Icons/line/LineSearchIcon'
import LineMountainsSunIcon from 'components/Luxkit/Icons/line/LineMountainsSunIcon'
import LineUserPlusIcon from 'components/Luxkit/Icons/line/LineUserPlusIcon'
import LineGiftIcon from 'components/Luxkit/Icons/line/LineGiftIcon'
import LineTicketIcon from 'components/Luxkit/Icons/line/LineTicketIcon'
import LineTagAltIcon from 'components/Luxkit/Icons/line/LineTagAltIcon'
import LineBoltAltIcon from 'components/Luxkit/Icons/line/LineBoltAltIcon'
import LineBullseyeIcon from 'components/Luxkit/Icons/line/LineBullseyeIcon'
import LineUserIcon from 'components/Luxkit/Icons/line/LineUserIcon'
import LineGlobeIcon from 'components/Luxkit/Icons/line/LineGlobeIcon'
import LineStarIcon from 'components/Luxkit/Icons/line/LineStarIcon'
import LineHotAirBalloonIcon from 'components/Luxkit/Icons/line/LineHotAirBalloonIcon'
import LinePlaneDepartureIcon from 'components/Luxkit/Icons/line/LinePlaneDepartureIcon'
import LineUltraLuxDiamondIcon from 'components/Luxkit/Icons/line/LineUltraLuxDiamondIcon'
import LineShipIcon from 'components/Luxkit/Icons/line/LineShipIcon'
import LineCompassIcon from 'components/Luxkit/Icons/line/LineCompassIcon'
import LineBedIcon from 'components/Luxkit/Icons/line/LineBedIcon'
import LineStopwatchIcon from 'components/Luxkit/Icons/line/LineStopwatchIcon'
import LineCarIcon from 'components/Luxkit/Icons/line/LineCarIcon'
import LineHomeAltIcon from 'components/Luxkit/Icons/line/LineHomeAltIcon'
import LineHeartIcon from 'components/Luxkit/Icons/line/LineHeartIcon'
import SolidPlusIcon from 'components/Luxkit/Icons/solid/SolidPlusIcon'

const iconMap: Record<string, React.FunctionComponentFactory<SvgIconProps>> = {
  star: SolidStarIcon,
  search: LineSearchIcon,
  'mountains-sun': LineMountainsSunIcon,
  gift: LineGiftIcon,
  'user-plus': LineUserPlusIcon,
  'plane-departure': LinePlaneDepartureIcon,
  ticket: LineTicketIcon,
  'tag-alt': LineTagAltIcon,
  'bolt-alt': LineBoltAltIcon,
  bulleyse: LineBullseyeIcon,
  user: LineUserIcon,
  globe: LineGlobeIcon,
  'line-star': LineStarIcon,
  'hot-air-balloon': LineHotAirBalloonIcon,
  'ultra-lux-diamond': LineUltraLuxDiamondIcon,
  ship: LineShipIcon,
  compass: LineCompassIcon,
  bed: LineBedIcon,
  stopwatch: LineStopwatchIcon,
  car: LineCarIcon,
  'home-alt': LineHomeAltIcon,
  heart: LineHeartIcon,
  plus: SolidPlusIcon,
}

export const getNavigationMenuIcon = (name: string) => iconMap[name]
