import FormatCurrency from 'components/Common/FormatCurrency'
import BodyText from 'components/Luxkit/Typography/BodyText'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'
import FormatDecimal from 'components/utils/Formatters/FormatDecimal'
import CurrencyContext from 'contexts/currencyContext'
import { useAppSelector } from 'hooks/reduxHooks'
import { rem } from 'polished'
import React, { useContext } from 'react'
import styled from 'styled-components'
import NavBarButtonBase from '../NavBarButton/NavBarButtonBase'
import NavBarUserAvatar from '../NavBar/NavBarUserAvatar'

const Button = styled(NavBarButtonBase)`
  padding: ${rem(12)};
  padding-left: ${rem(8)};
  display: flex;
  gap: ${rem(8)};
  align-items: center;
  text-align: start;
  max-width: ${rem(144)};
`

const NameSection = styled(CSSBreakpoint)`
  overflow: hidden;
`

interface Props {
  className?: string;
}

function NavBarUserButton(props: Props) {
  const account = useAppSelector(state => state.auth.account)
  const currency = useContext(CurrencyContext)
  const loyaltyAccount = useAppSelector(state => state.luxLoyalty.account.data)
  const credits = useAppSelector(state => state.auth.account.creditsByCurrency[currency])
  const loyaltyPoints = loyaltyAccount?.points ?? 0

  return <Button
    to="/account"
    className={props.className}
    data-testid="nav-account"
  >
    <NavBarUserAvatar />
    <NameSection min="desktop">
      <BodyText variant="medium" wrap="truncate" colour="neutral-one">
        {account.givenName || 'Account'}
      </BodyText>
      {!!loyaltyPoints && <BodyText variant="small" colour="neutral-two">
        <FormatDecimal value={loyaltyPoints} /> pts
      </BodyText>}
      {!!credits?.balance && !loyaltyPoints && <BodyText variant="small" colour="neutral-two">
        <FormatCurrency value={credits.balance} />
      </BodyText>}
    </NameSection>
  </Button>
}

export default React.memo(NavBarUserButton)
