import AccountAccessModal from 'components/Account/AccountAccess/AccountAccessModal'
import TextButton from 'components/Luxkit/Button/TextButton'
import ListItem from 'components/Luxkit/List/ListItem'
import ModalBase from 'components/Luxkit/Modal/ModalBase'
import ModalBody from 'components/Luxkit/Modal/ModalBody'
import ModalContent from 'components/Luxkit/Modal/ModalContent'
import ModalFooter from 'components/Luxkit/Modal/ModalFooter'
import ModalHeader from 'components/Luxkit/Modal/ModalHeader'
import ModalContext from 'contexts/ModalContext'
import useModalElementContext from 'hooks/Modal/useModalElementContext'
import { useAppSelector } from 'hooks/reduxHooks'
import React, { useCallback, useContext, useMemo } from 'react'
import { selectLoggedIn } from 'selectors/accountSelectors'
import { getFlatNavItems } from 'selectors/navigationSelectors'
import NavBarHamburgerMenuItem from './NavBarHamburgerMenuItem'
import Divider from 'components/Luxkit/Divider'
import VerticalSpacer from 'components/Common/Spacing/VerticalSpacer'
import NavBarContactsItems from '../NavBarButtons/NavBarContactsItems'
import GeoContext from 'contexts/geoContext'
import Flag from 'components/Luxkit/Flag'
import { isLuxPlusEnabled } from 'luxPlus/selectors/featureToggle'
import FormatCurrency from 'components/Common/FormatCurrency'
import Modal from 'components/Luxkit/Modal/Modal'
import NavBarRegionItems from '../NavBarButtons/NavBarRegionItems'
import { getIsLuxLoyaltyEnabled } from 'luxLoyalty/selectors/luxLoyaltyFeatureToggles'
import LineUserCircleIcon from 'components/Luxkit/Icons/line/LineUserCircleIcon'
import NavBarHamburgerMenuLoyaltyItem from './NavBarHamburgerMenuLoyaltyItem'
import NavBarUserAvatar from '../NavBar/NavBarUserAvatar'
import useToggle from 'hooks/useToggle'
import DropdownChevron from 'components/Luxkit/Dropdown/DropdownChevron'
import SlideDown from 'components/utils/SlideDown'
import { partitionBy, skip, take } from 'lib/array/arrayUtils'
import styled from 'styled-components'
import { rem } from 'polished'
import LogoIcon from 'components/App/Header/Logo/LogoIcon'
import LineAngleRightIcon from 'components/Luxkit/Icons/line/LineAngleRightIcon'
import CSSBreakpoint from 'components/utils/CSSBreakpoint'

const MoreContainer = styled(SlideDown)`
  padding-left: ${rem(12)};
`

function NavBarHamburgerMenu() {
  const [showMore, toggleMore] = useToggle()
  const { currentRegionCode, currentCurrency } = useContext(GeoContext)
  const { resolve } = useModalElementContext()
  const showModal = useContext(ModalContext)
  const loggedIn = useAppSelector(selectLoggedIn)
  const account = useAppSelector(state => state.auth.account)
  const navItems = useAppSelector(state => getFlatNavItems(state))
  const luxPlusEnabled = useAppSelector(isLuxPlusEnabled)
  const loyaltyEnabled = useAppSelector(getIsLuxLoyaltyEnabled)

  const onMoreClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>((e) => {
    toggleMore()
    if (!showMore) {
      // toggling it on, scroll it into view
      e.currentTarget.scrollIntoView({ block: 'start', behavior: 'smooth' })
    }
  }, [showMore, toggleMore])

  const onRegionClick = useCallback(() => {
    showModal(resolve => <Modal
      size="S"
      title="Select your region & currency"
      >
      <NavBarRegionItems onRegionSelect={resolve} />
    </Modal>)
  }, [showModal])
  const onLogin = useCallback(() => showModal(<AccountAccessModal />), [showModal])

  const closeModal = useCallback(() => resolve(), [resolve])

  const hasSomeLoyalty = luxPlusEnabled || loyaltyEnabled

  const [primaryItems, secondaryItems, moreItems] = useMemo(() => {
    const [primaryNavs, otherItems] = partitionBy(navItems, nav => nav.category === 'primary')
    if (navItems.length <= 11) {
      // We show 10 items and put anything else in more, but there's no point
      // in showing a 'more' with one item, hence we break on 11 here, not 10
      return [primaryNavs, otherItems, []]
    }

    const remainingItems = 10 - primaryNavs.length
    return [
      primaryNavs,
      take(otherItems, remainingItems),
      skip(otherItems, remainingItems),
    ]
  }, [navItems])

  return <ModalBase mode="menu">
    <ModalHeader title={<LogoIcon height="1em" width={null}/>} />
    <ModalBody>
      <ModalContent>
        <VerticalSpacer gap={8}>
          {primaryItems.map(item => <NavBarHamburgerMenuItem
            key={item.text}
            navItem={item}
            onClick={closeModal}
          />)}
          {!!secondaryItems.length && <>
            <Divider kind="secondary" />
            {secondaryItems.map(item => <NavBarHamburgerMenuItem
              key={item.text}
              navItem={item}
              onClick={closeModal}
            />)}
          </>}
          {!!moreItems.length && <>
            <ListItem
              size="medium"
              onClick={onMoreClick}
              title="More"
              endIcon={<DropdownChevron open={showMore} />}
            />
            <MoreContainer show={showMore} animateOpacity>
              {moreItems.map(item => <NavBarHamburgerMenuItem
                key={item.text}
                navItem={item}
                onClick={closeModal}
                hideIcon
              />)}
            </MoreContainer>
          </>}
          <Divider kind="secondary" />
          {hasSomeLoyalty && <>
            {luxPlusEnabled && <NavBarHamburgerMenuLoyaltyItem
              variant="luxPlus"
              onClick={closeModal}
            />}
            {loyaltyEnabled && <NavBarHamburgerMenuLoyaltyItem
              variant="loyalty"
              onClick={closeModal}
              isNew
            />}
            <Divider kind="secondary" />
          </>}
          <div>
            <NavBarContactsItems />
          </div>
          <Divider kind="secondary" />
          <div>
            <ListItem
              size="medium"
              title={currentCurrency}
              onClick={onRegionClick}
              startIcon={<Flag countryCode={currentRegionCode} size="S" />}
              endIcon={<CSSBreakpoint max="mobile" as={LineAngleRightIcon} />}
            />
            {loggedIn && <ListItem
              onClick={closeModal}
              size="medium"
              to="/account"
              title={account.givenName}
              subtitle={account.balance > 0 ? <FormatCurrency value={account.balance} format="dynamicDollar" /> : undefined }
              aria-label="Account"
              startIcon={<NavBarUserAvatar />}
            />}
          </div>
        </VerticalSpacer>
      </ModalContent>
    </ModalBody>
    {!loggedIn && <ModalFooter>
      <TextButton
        kind="secondary"
        onClick={onLogin}
        size="large"
        fit="full-width"
        startIcon={<LineUserCircleIcon />}
      >
        Sign up or Login
      </TextButton>
    </ModalFooter>}
  </ModalBase>
}

export default NavBarHamburgerMenu
